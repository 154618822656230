import docReady from '../../../assets/js/docReady';

function initEnergyUpdate() {
    const carousel = document.querySelector('.energy-update-carousel .carousel-container');
    const cards = carousel.querySelectorAll('.carousel-card');
    const prevBtn = document.querySelector('.energy-update-carousel .carousel-control.prev');
    const nextBtn = document.querySelector('.energy-update-carousel .carousel-control.next');
    const lazyImages = carousel.querySelectorAll('img[loading="lazy"]');

    const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
            lazyImages.forEach((img) => img.removeAttribute('loading'));
            observer.disconnect();
        }
    }, { threshold: 0.1 });

    observer.observe(carousel);

    function updateFocusedCard() {
        const containerRect = carousel.getBoundingClientRect();
        let maxVisibleArea = 0;
        let focusedCard = null;

        cards.forEach((card) => {
            const cardRect = card.getBoundingClientRect();
            const visibleWidth = Math.min(cardRect.right, containerRect.right) - Math.max(cardRect.left, containerRect.left);
            const visibleArea = visibleWidth * cardRect.height;

            if (visibleArea > maxVisibleArea) {
                maxVisibleArea = visibleArea;
                focusedCard = card;
            }
        });

        cards.forEach((card) => card.classList.remove('focused'));
        if (focusedCard) {
            focusedCard.classList.add('focused');
        }

        updateNavButtons();
    }

    function updateNavButtons() {
        const { scrollLeft } = carousel;
        const { scrollWidth } = carousel;
        const { clientWidth } = carousel;
        const tolerance = 0; // Pixels of tolerance for rounding errors

        // Show prev button if we're not at the start (with tolerance)
        prevBtn.style.display = scrollLeft > tolerance ? 'flex' : 'none';

        // Show next button if we're not at the end (with tolerance)
        nextBtn.style.display = scrollLeft < scrollWidth - clientWidth - tolerance ? 'flex' : 'none';
    }

    function getCardWidth() {
        const card = cards[0];
        const cardStyle = window.getComputedStyle(card);
        const cardWidth = card.offsetWidth;
        const cardMarginLeft = parseFloat(cardStyle.marginLeft);
        const cardMarginRight = parseFloat(cardStyle.marginRight);
        return cardWidth + cardMarginLeft + cardMarginRight;
    }

    function scrollToCard(direction) {
        const totalCardWidth = getCardWidth();
        const currentScrollPosition = carousel.scrollLeft;
        const currentCardIndex = Math.round(currentScrollPosition / totalCardWidth);

        let targetCardIndex;
        if (direction === 'next') {
            targetCardIndex = currentCardIndex + 1;
        } else {
            targetCardIndex = currentCardIndex - 1;
        }

        // Ensure the target index is within bounds
        targetCardIndex = Math.max(0, Math.min(targetCardIndex, cards.length - 1));

        const targetScrollPosition = targetCardIndex * totalCardWidth;
        carousel.scrollTo({ left: targetScrollPosition, behavior: 'smooth' });
    }

    function scrollToClickedCard(card) {
        const cardIndex = Array.from(cards).indexOf(card);
        const totalCardWidth = getCardWidth();
        const scrollAmount = cardIndex * totalCardWidth;
        carousel.scrollTo({ left: scrollAmount, behavior: 'smooth' });
    }

    function handleInternalLinks() {
        document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth',
                });
            });
        });
    }

    carousel.addEventListener('scroll', updateFocusedCard);
    nextBtn.addEventListener('click', () => scrollToCard('next'));
    prevBtn.addEventListener('click', () => scrollToCard('prev'));
    carousel.addEventListener('scrollend', updateNavButtons);

    // Add click event listeners to cards (for desktop)
    if (window.innerWidth >= 768) { // Assuming 1024px is your desktop breakpoint
        cards.forEach((card) => {
            card.addEventListener('click', () => {
                if (!card.classList.contains('focused')) {
                    scrollToClickedCard(card);
                }
            });
        });
    }

    // Initial update
    updateFocusedCard();
    handleInternalLinks();

    // Enable native touch scrolling
    carousel.style.overflowX = 'scroll';
    carousel.style.webkitOverflowScrolling = 'touch';
}

docReady(() => {
    initEnergyUpdate();
});
